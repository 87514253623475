var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"category"},[_c('div',{staticClass:"category__nav"},[_c('div',{staticClass:"category__nav-container"},[_c('div',{staticClass:"category-nav"},[_c('div',{ref:"categoryToggle",staticClass:"category-nav__toggle",class:{
            'category-nav__toggle--active': _vm.toggleActive,
          },attrs:{"role":"button"},on:{"click":_vm.toggleOnClick}},[_c('span',{ref:"categoryToggleText",staticClass:"category-nav__toggle-text"},[_vm._v(" Kategorie ")])]),_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onCategoryListClickOutside),expression:"onCategoryListClickOutside"}],staticClass:"category-nav__list"},_vm._l((_vm.categories),function(ref){
          var slug = ref.slug;
          var name = ref.name;
return _c('li',{key:slug,staticClass:"category-nav__item",class:{
              'category-nav__item--active': slug === _vm.category,
            }},[_c('router-link',{staticClass:"category-nav__link",attrs:{"to":{
                name: 'category',
                params: {
                  category: slug,
                },
                query: {
                  perPage: _vm.perPage,
                  panoramic: _vm.panoramic,
                  orientation: _vm.orientation,
                  premium: _vm.premium,
                },
              }}},[_vm._v(" "+_vm._s(name)+" ")])],1)}),0)])])]),_c('div',{staticClass:"container"},[_c('section',{staticClass:"category__content",class:{
        'category__content--loading': _vm.loading,
      }},[_c('header',{staticClass:"category__header"},[_c('h1',{staticClass:"category__name"},[_vm._v(" Zdjęć w kategorii "+_vm._s(_vm.term.name)+": "),_c('strong',[_vm._v(_vm._s(_vm.term.count))])]),_c('div',{staticClass:"category__toolbar"},[_c('Toolbar',{attrs:{"orientation":_vm.orientation,"panoramic":_vm.panoramic,"premium":_vm.premium,"per-page":_vm.perPage},on:{"change":_vm.toolbarOnChange}})],1)]),(!_vm.loading)?_c('ImagesGrid',{attrs:{"images":_vm.products}}):_vm._e(),(_vm.loading)?_c('ImagesGridSkeleton',{attrs:{"fixed-layout":true}}):_vm._e(),(_vm.products.length === 0 && !_vm.loading)?_c('div',{staticClass:"no-results"},[_c('div',{staticClass:"info-box info-box--simple"},[_c('p',{staticClass:"info-box__text"},[_vm._v(" Brak wyników spełniających kryteria wyszukiwania ")]),_c('div',{staticClass:"info-box__button"},[_c('router-link',{staticClass:"button button--strong",attrs:{"to":{
                name: 'category',
                params: {
                  category: _vm.term.slug,
                },
              }}},[_vm._v(" Zobacz wszystkie zdjęcia z kategorii: "),_c('strong',[_vm._v(_vm._s(_vm.term.name))])])],1)])]):_vm._e()],1),(_vm.productsPages > 1)?_c('div',{staticClass:"category__pagination"},[_c('Pagination',{attrs:{"current":_vm.page,"total":_vm.productsTotal,"pages":_vm.productsPages,"loading":_vm.loading},on:{"change":_vm.paginationOnChange}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }